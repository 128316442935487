import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './Section2.scss';

const Section2: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Section2">
      <div className="columns">
        <div className="column banner-column">
          <span>{t('section2.banner1')}</span>
          <span>{t('section2.banner2')}</span>
          <span>{t('section2.banner3')}</span>
          <span>{t('section2.banner4')}</span>
        </div>
      </div>
      <div className="columns">
        <div className="column text-column">
          <ul>
            <li>
              {t('section2.li1.part1')}
              <a
                href="https://mil.ee/"
                className="bold"
                target="_blank"
                rel="noreferrer"
              >
                {t('section2.li1.part2')}
              </a>
              {t('section2.li1.part3')}
              <a
                href="https://kra.ee/"
                className="bold"
                target="_blank"
                rel="noreferrer"
              >
                {t('section2.li1.part4')}
              </a>
              {t('section2.li1.part5')}
              <a
                href={t('button link')}
                className="bold"
                target="_blank"
                rel="noreferrer"
              >
                eesti.ee
              </a>{' '}
              {t('section2.li1.part6')}
              <span className="bold">{t('section2.li1.part7')}</span>
            </li>
            <li>
              {t('section2.li2.part1')}
              <span className="bold">@eesti.ee</span>
              {t('section2.li2.part2')}
            </li>
            <li>{t('section2.li3')}</li>
          </ul>
          <span className="red-dash"></span>
        </div>
      </div>
    </div>
  );
};

export default Section2;
