import React, { ReactElement } from 'react';
import './NotFound404.scss';
import { Link } from 'react-router-dom';

const NotFound404: React.FC = (): ReactElement => {
  return (
    <div className="NotFound404">
      <div className="header">
        <h1>404</h1>
      </div>
      <div className="text">Page not found</div>
      <div className="description">
        Requested URL was not found, click{' '}
        <Link to="/" className="link">
          here
        </Link>{' '}
        to go back
      </div>
    </div>
  );
};

export default NotFound404;
