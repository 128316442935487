import React, { ReactElement, useEffect } from 'react';
import './App.scss';
import Section1 from './components/Section1/Section1';
import Section2 from './components/Section2/Section2';
import Section3 from './components/Section3/Section3';
import Section4 from './components/Section4/Section4';
import Section5 from './components/Section5/Section5';
import Footer from './components/Footer/Footer';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// import OgImage from './assets/images/KM_Ekutsed_FB_1200x628px.jpg';

type LangParams = {
  lng: string;
};

const App: React.FC = (): ReactElement => {
  const { t, i18n } = useTranslation();
  let { lng } = useParams<LangParams>();

  useEffect(() => {
    const switchLanguage = (lang: string) => {
      i18n.changeLanguage(lang);
    };

    if (lng === 'et') {
      switchLanguage('et');
    } else if (lng === 'ru') {
      switchLanguage('ru');
    }
  }, [lng, i18n]);

  return (
    <div className="App">
      <Helmet>
        <meta property="og:title" content={t('meta title')} />
        <meta property="og:description" content={t('meta description')} />
        <meta property="og:url" content="https://operatsioonkiri.ee/" />

        <meta name="twitter:title" content={t('meta title')} />
        <meta name="twitter:description" content={t('meta description')} />
      </Helmet>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Footer />
    </div>
  );
};

export default App;
