import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './Section3.scss';

const Section3: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Section3">
      <div className="columns">
        <div className="column text-column">
          <div className="tabbed-text-container">
            <span className="tab">{t('section3.header')}</span>
            <div className="text">
              <div className="columns">
                <div className="column">
                  <p>{t('section3.text1')}</p>
                  <p>{t('section3.text2')}</p>
                  <p>
                    {t('section3.text3.part1')}
                    <a
                      href={t('button link')}
                      className="bold"
                      target="_blank"
                      rel="noreferrer"
                    >
                      eesti.ee
                    </a>
                    {t('section3.text3.part2')}
                    <a
                      href={t('button link')}
                      className="bold"
                      target="_blank"
                      rel="noreferrer"
                    >
                      eesti.ee
                    </a>
                    {t('section3.text3.part3')}
                    <span className="bold">@eesti.ee</span>
                    {t('section3.text3.part4')}
                    <a
                      href={t('button link')}
                      className="bold"
                      target="_blank"
                      rel="noreferrer"
                    >
                      eesti.ee
                    </a>
                    {t('section3.text3.part5')}
                  </p>
                </div>
                <div className="column">
                  <p>
                    {t('section3.text4.part1')}
                    <a
                      href={t('button link')}
                      className="bold"
                      target="_blank"
                      rel="noreferrer"
                    >
                      eesti.ee
                    </a>
                    {t('section3.text4.part2')}
                  </p>
                  <p>{t('section3.text5')}</p>
                  <p>{t('section3.text6')}</p>
                  <p>
                    {t('section3.text7.part1')}
                    <a
                      href={t('button link')}
                      className="bold"
                      target="_blank"
                      rel="noreferrer"
                    >
                      eesti.ee
                    </a>
                    {t('section3.text7.part2')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <span className="red-dash"></span>
        </div>
      </div>
    </div>
  );
};

export default Section3;
