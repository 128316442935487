import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';

const Footer: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Footer">
      <a
        href={t('button link')}
        target="_blank"
        rel="noreferrer"
        className="fancy-button"
      >
        {t('button text 1')}
        <span className="eesti-ee-span">eesti.ee</span>
        {t('button text 2')}
      </a>
      <div className="container">
        <div className="columns header-columns">
          <div className="column is-one-fifth is-offset-two-fifths">
            <span>{t('footer.header')}</span>
          </div>
        </div>
        <div className="columns link-columns">
          <div className="column is-one-fifth is-offset-two-fifths">
            <div className="link-group">
              <span>Kaitseväeteenistuse veeb</span>
              <a
                href="https://kaitsevaeteenistus.ee/"
                target="_blank"
                rel="noreferrer"
              >
                kaitsevaeteenistus.ee
              </a>
            </div>
            <div className="link-group">
              <span>Kaitsevägi</span>
              <a href="https://mil.ee/" target="_blank" rel="noreferrer">
                mil.ee
              </a>
            </div>
            <div className="link-group">
              <span>Kaitseressursside Amet</span>
              <a href="https://kra.ee/" target="_blank" rel="noreferrer">
                kra.ee
              </a>
            </div>
            <div className="link-group">
              <span>Kaitseministeerium</span>
              <a
                href="https://kaitseministeerium.ee/"
                target="_blank"
                rel="noreferrer"
              >
                kaitseministeerium.ee
              </a>
            </div>
          </div>
          <div className="column is-one-fifth">
            <div className="link-group">
              <span>Sõdurileht</span>
              <a
                href="https://www.facebook.com/s6durileht"
                target="_blank"
                rel="noreferrer"
              >
                facebook.com/s6durileht
              </a>
            </div>
            <div className="link-group">
              <span>Kaitseressursside Amet</span>
              <a
                href="https://www.facebook.com/kaitseressurssideamet"
                target="_blank"
                rel="noreferrer"
              >
                facebook.com/kaitseressurssideamet
              </a>
            </div>
            <div className="link-group">
              <span>Kaitseministeerium</span>
              <a
                href="https://www.facebook.com/kaitseministeerium"
                target="_blank"
                rel="noreferrer"
              >
                facebook.com/kaitseministeerium
              </a>
            </div>
            <div className="link-group">
              <span>Naised vormi</span>
              <a
                href="https://www.facebook.com/NaisedRiigikaitses"
                target="_blank"
                rel="noreferrer"
              >
                facebook.com/NaisedRiigikaitses
              </a>
            </div>
          </div>
          <div className="column is-one-fifth">
            <div className="link-group">
              <span>1. jalaväebrigaad</span>
              <a
                href="https://www.facebook.com/1jalavaebrigaad/"
                target="_blank"
                rel="noreferrer"
              >
                facebook.com/1jalavaebrigaad
              </a>
            </div>
            <div className="link-group">
              <span>2. jalaväebrigaad</span>
              <a
                href="https://www.facebook.com/2JVBr/"
                target="_blank"
                rel="noreferrer"
              >
                facebook.com/2JVBr
              </a>
            </div>
            <div className="link-group">
              <span>Õhuvägi</span>
              <a
                href="https://www.facebook.com/EestiOhuvagi/"
                target="_blank"
                rel="noreferrer"
              >
                facebook.com/EestiOhuvagi
              </a>
            </div>
            <div className="link-group">
              <span>Merevägi</span>
              <a
                href="https://www.facebook.com/merevagi/"
                target="_blank"
                rel="noreferrer"
              >
                facebook.com/merevagi
              </a>
            </div>
          </div>
        </div>
        <div className="columns campaign-columns">
          <div className="column">
            <span>{t('footer.campaign')}</span>
          </div>
        </div>
        <div className="columns privacy-columns">
          <div className="column">
            {/* <a href="#!" target="_blank" rel="noreferrer">
              TERMS
            </a>
            <a href="#!" target="_blank" rel="noreferrer">
              PRIVACY POLICY
            </a>
            <a href="#!" target="_blank" rel="noreferrer">
              CONTACT
            </a>
            <a href="#!" target="_blank" rel="noreferrer">
              JOB
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
