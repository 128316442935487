import React, { ReactElement } from 'react';
import './Section5.scss';
import Image from '../../assets/images/pic.png';
import { useTranslation } from 'react-i18next';

const Section5: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Section5">
      <div className="columns">
        <div className="column text-column">
          <h2>{t('section5.header')}</h2>
          <p className="bold">{t('section5.text1')}</p>
          <ul>
            <li>{t('section5.li1')}</li>
            <li>{t('section5.li2')}</li>
            <li>{t('section5.li3')}</li>
            <li>{t('section5.li4')}</li>
            <li>{t('section5.li5')}</li>
            <li>{t('section5.li6')}</li>
            <li>{t('section5.li7')}</li>
            <li>{t('section5.li8')}</li>
          </ul>
          <p>{t('section5.text2')}</p>
          <span className="red-dash"></span>
        </div>
        <div className="column image-column">
          <img src={Image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
