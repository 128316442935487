import React, { ReactElement } from 'react';
import './Section1.scss';
import Logo from '../../assets/images/logo.png';
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed';
// import Video from '../../assets/video/kaitsevägi_ekutse_final_11.10_compressed.mp4';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Section1: React.FC = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  // const switchLanguage = (lang: string) => {
  //   i18n.changeLanguage(lang);
  // };

  return (
    <div className="Section1">
      {/* <button
        type="button"
        className="language-button"
        onClick={() =>
          getLanguage() === 'et' ? switchLanguage('ru') : switchLanguage('et')
        }
      >
        {getLanguage() === 'et' ? 'RU' : 'ET'}
      </button> */}
      <Link
        className="language-button"
        to={getLanguage() === 'et' ? '/ru' : '/et'}
      >
        {getLanguage() === 'et' ? 'RU' : 'ET'}
      </Link>

      <div className="columns">
        <div className="column logo-column">
          <img src={Logo} alt="eesti kaitsevägi" />
        </div>
      </div>
      <div className="columns">
        <div className="column text-column">
          <h1>
            <span>{t('section1.operatsioon')}</span>{' '}
            <span>{t('section1.kiri')}</span>
            <span>
              <span className="left-line"></span>
              <h2>{t('section1.on alanud')}</h2>
              <span className="right-line"></span>
            </span>
          </h1>

          <p>
            {t('section1.description.part1')}
            <a
              href={t('button link')}
              className="bold"
              target="_blank"
              rel="noreferrer"
            >
              eesti.ee
            </a>
            {t('section1.description.part2')}
          </p>
          {/* <a href="#!">
            <img src={Button} alt="" />
          </a> */}
          <a
            href={t('button link')}
            target="_blank"
            rel="noreferrer"
            className="fancy-button"
          >
            {t('button text 1')}
            <span className="eesti-ee-span">eesti.ee</span>
            {t('button text 2')}
          </a>
        </div>
      </div>
      <div className="columns">
        <div className="column video-column">
          <div className="video-container">
            <YoutubeEmbed embedId={t('video_1')} />
            {/* <div className="video-responsive">
              <video width="853" height="480" controls>
                <source src={Video} type="video/mp4"></source>
              </video>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
