import React, { ReactElement } from 'react';
import './Section4.scss';
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed';
import { useTranslation } from 'react-i18next';

const Section4: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Section4">
      <div className="columns">
        <div className="column is-one-third text-column">
          <h2>{t('section4.header')}</h2>
          <p>
            {t('section4.text1.part1')}
            <span className="bold">@eesti.ee</span>
            {t('section4.text1.part2')}
            <span className="bold">@eesti.ee</span>
            {t('section4.text1.part3')}
          </p>
          <p>
            {t('section4.text2.part1')}
            <span className="bold">@eesti.ee</span>
            {t('section4.text2.part2')}
            <a
              href={t('button link')}
              className="bold"
              target="_blank"
              rel="noreferrer"
            >
              eesti.ee
            </a>
            {t('section4.text2.part3')}
          </p>
          <p>
            {t('section4.text3.part1')}
            <span className="bold">@eesti.ee</span>
            {t('section4.text3.part2')}
          </p>
          <span className="red-dash"></span>
        </div>
        <div className="column is-two-thirds video-column">
          <div className="video-container">
            <YoutubeEmbed embedId={t('video_2')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
